















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue, IRelationBaseValue } from "@/Services/Interfaces";
import { globalUpdateDetailService } from "@/Services/GlobalService";
import { InfoType, CrudType } from "@/Services/enums";
import ActionComponent from "./ActionComponent.vue";
import BaseSubComponent from "./BaseSubComponent.vue";
import MemoComponent from "./MemoComponent.vue";
import router from "@/router";
import DeleteButton from "../Controls/DeleteButton.vue";


@Component({ components: { ActionComponent, BaseSubComponent, MemoComponent, DeleteButton } })
export default class RelationComponent extends BaseSubComponent {
  constructor() {
    super();
    this.name = "RelationComponent";
    this.canAction = false;
  }

  hasPictures() :  boolean
  {
    return this.thismodel.fieldname.toLowerCase() == "bilder";
  }


getLabel(fld: IRelationBaseValue ) : string
{
   return fld.child_base_identifier;
}

startGallery(): void
{
    router.push("/gallery/" + this.thismodel.base_id);
}

  edit(e: number): void {
    router.push("/dyn/" + e + "/update/" + this.thismodel.fieldname);
  }
  thismodel: IStrDetailValue = <IStrDetailValue>{};

  canNew(thismodel: IStrDetailValue) : boolean
  {
    return ( thismodel && thismodel.id > 1);
  }


  canDelete(thismodel: IStrDetailValue) : boolean
  {
    return ( thismodel !=  undefined) && ( !thismodel.relations || thismodel.relations.length == 0 );
  }



  newRelation(): void {
    router.push(
      "/dynchild/-1/create/" +
        this.thismodel.fieldname +
        "/" +
        this.thismodel.id
    );
  }



  onUpdate(model: any) {
    if (this.canUpdate(model)) {
      model.crud = CrudType.updatestrvalue;
      globalUpdateDetailService.sendMessage(model);
    }
  }

  public data() {
    return {
      thismodel: this.model,
      canAction: false
    };
  }

  computed() {}
}
